.container{
  background-color: #fff;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
}
.container:hover img{
  opacity: 0.5;
  transform: translateY(-20px);
  transition: 0.5s ease;
}
.container:hover .details{
  display: flex;
  flex-direction: column;
  transition: 1s ease;
}
img{
  object-fit: fill;
  height: 100%;
  width: 100%;
  z-index: -1;
}
.details{
  position: absolute;
  bottom: 0;
  display: block;
  background-color: #fff;
  width: 100%;
  padding: 15px;
  font-family: Mainfont;
  display: none;
  transition: 1s ease;
  text-align: center;
  opacity: 0.90;
}
.social-media{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 10px;
  padding: 10px;
}
